<template>
<div class='integrationsClinkio field'>
	<TextInput 
		v-model='apiKey' 
		maxlength='100' 
		:placeholder='$t("views.integrations.cliniko.phApiKey")' 
		:class='{ err }'
		@input='err = false'
	/>
</div>
</template>

<script>
import TextInput from '@/components/common/TextInput'

export default {
	name: 'IntegrationsCliniko',
	components: { TextInput },
	data() {
		return {
			err: false,
			apiKey: ''
		}
	},
	methods: {
		get() { return { apiKey: this.apiKey }},
		showErr() { this.err = true },
		reset() { this.apiKey = '' }
	}
}
</script>

<style lang='scss'>
.integrationsClinkio {
}
</style>
